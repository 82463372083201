<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        设备类型：
        <el-select filterable v-model="csblx" placeholder="全部" clearable ref="s" @visible-change="$visibleChange($event, 's')">
          <el-option :label="item.codeDesc" v-for="(item, index) in csblxArr" :key="index" :value="item.code"></el-option>
        </el-select>
      </div>
      <div>
        设备名称：
        <el-input placeholder="请输入设备名称" v-model="csbmc" clearable></el-input>
      </div>
      <div>
        设备编号：
        <el-input placeholder="请输入设备编号" v-model="csbcode" clearable></el-input>
      </div>
      <div>
        在线/离线：
        <el-select v-model="cisOnline" clearable>
          <el-option label="离线" :value="1"></el-option>
          <el-option label="在线" :value="0"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewMgrDev')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button v-if="$has('viewMgrDev')" type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
        <el-button v-if="$has('addMgrDev')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0, 0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="101">
          <template slot-scope="{ row }">
            {{ row.areaName || '[暂未绑定]' }}
          </template>
        </el-table-column>
        <el-table-column prop="organizationName" label="机构名称" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{ row.organizationName || '[暂未绑定]' }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="设备类型" show-overflow-tooltip>
          <template slot-scope="{ row }">{{ csblxArr.find((v) => v.code == row.type).codeDesc }}</template>
        </el-table-column>
        <el-table-column prop="name" label="设备名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="deviceCode" label="设备编号" show-overflow-tooltip class-name="cellSty">
          <template slot-scope="{ row }">
            <div :class="{ cellHover: row.useFlag === 0, ovText: true }" @click="deviceClk(row)">{{ row.deviceCode }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="deviceIp" label="设备IP" show-overflow-tooltip></el-table-column> -->
        <!-- <el-table-column prop="devicePort" label="设备端口" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="isOnline" label="在线/离线">
          <template slot-scope="{ row }">
            <!-- <div :style="row.isOnline == 1 ? 'background:red;color:#fff' : ''">{{ row.isOnline == 0 ? '在线' : row.isOnline == 1 ? '离线' : '不涉及' }}</div> -->
            <el-tag :type="row.isOnline == 0 ? 'success' : row.isOnline == 1 ? 'danger' : ''">{{ row.isOnline == 0 ? '在线' : row.isOnline == 1 ? '离线' : '不涉及' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="useFlag" label="状态" width="50">
          <template slot-scope="{ row }">
            <div>{{ row.useFlag == 0 ? '启用' : '禁用' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createDt" label="创建时间" width="160"></el-table-column>
        <el-table-column prop="lastDataDt" label="最近一次数据时间" width="160"></el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="230" class-name="btnList">
          <template slot-scope="{ row }">
            <el-button v-if="$has('editMgrDev')" @click="openDialog(1, row)" type="primary" size="small" icon="el-icon-edit-outline">编 辑</el-button>
            <el-button v-if="$has('unbindBle') && row.gatewayId" @click="unbind(row)" type="warning" size="small" icon="el-icon-link">解 绑</el-button>
            <el-button v-if="$has('delMgrDev')" type="danger" size="small" @click="deletes(row.id)" icon="el-icon-close">删 除</el-button>
            <el-button v-if="$has('viewMgrDevId')" type="success" size="small" @click="openDialog(2, row)" icon="el-icon-view">设备授权码</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 新增、编辑模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" v-dialogDrag @close="cancles" center>
        <div class="dialogsC">
          <div class="newDialog" v-if="!isLook">
            <input-list ref="editIpt" @setData="getData" iptType="2"></input-list>
            <div>
              <span>设备名称：</span>
              <el-input placeholder="请输入设备名称" v-model="paramsObj.name" clearable></el-input>
            </div>
            <div>
              <span>设备类型：</span>
              <el-select v-model="paramsObj.type" placeholder="全部" clearable filterable ref="se" @visible-change="$visibleChange($event, 'se')">
                <el-option :label="item.codeDesc" v-for="(item, index) in csblxArr" :key="index" :value="item.code"></el-option>
              </el-select>
            </div>
            <div>
              <span>设备编号：</span>
              <el-input :disabled="isUseFlag" placeholder="请输入设备编号" v-model="paramsObj.deviceCode" clearable></el-input>
            </div>
            <!-- <div><span>设备IP:</span>
              <el-input placeholder="请输入设备IP" v-model="paramsObj.deviceIp" clearable></el-input>
            </div>
            <div><span>设备端口:</span>
              <el-input type="number" placeholder="请输入设备端口" v-model="paramsObj.devicePort" clearable></el-input>
            </div> -->
            <div v-if="isUseFlag">
              <span>状态：</span>
              <el-select v-model="paramsObj.useFlag" clearable>
                <el-option :value="0" label="启用"></el-option>
                <el-option :value="1" label="禁用"></el-option>
              </el-select>
            </div>
            <div>
              <span>备注：</span>
              <el-input placeholder="请输入备注" v-model="paramsObj.remark" clearable></el-input>
            </div>
          </div>
          <div class="newDialog" v-if="isLook">
            <div>
              <span>设备名称：</span>
              <el-input v-model="ldeviceName" disabled clearable></el-input>
            </div>
            <div>
              <span>设备ID：</span>
              <el-input v-model="ldeviceId" disabled clearable></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="!isLook">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="confirmClk">确 定</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-if="isLook">
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { deviceQueryWithPage, deviceSave, deviceUpdate, deviceDelete, radMqttUnbindDevice } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: this.$store.state.deviceId[1] || 10,
      totals: 0,
      tableData: [], //数据
      cgxqy: '', //区域名称名称id
      cjgmc: '', //机构名称id
      csbmc: '', //设备名称id
      csblx: '', //设备类型
      csblxArr: JSON.parse(sessionStorage.getItem('deviceType')), //设备类型数组
      csbcode: '', //设备编号
      cisOnline: this.$store.state.deviceId[0], //在线/离线
      user: this.$store.state.user,
      paramsObj: {}, //新增编辑对象
      dialogVisible: false, //模态框显影
      dialogTitle: '', //模态框标题
      isUseFlag: false, //状态显影
      dialogType: '', //新增编辑标记
      ldeviceName: '', //查看
      ldeviceId: '', //查看
      isLook: false, //查看数据授权码
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg, iptType) {
      if (iptType == 1) {
        this.cgxqy = msg[0]
        this.cjgmc = msg[1]
      } else {
        this.paramsObj.areaId = msg[0]
        this.paramsObj.organizationId = msg[1]
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        name: this.csbmc,
        areaId: this.cgxqy,
        organizationId: this.cjgmc,
        type: this.csblx,
        deviceCode: this.csbcode,
        isOnline: this.cisOnline,
      }
      await deviceQueryWithPage(params)
        .then((data) => {
          this.tableData = data.result.records
          this.totals = data.result.total
        })
        .catch(() => {})
      loading.close()
    },
    // 展开新增,编辑模态框
    openDialog(flag, row) {
      this.dialogType = flag
      this.dialogVisible = true
      if (flag == 0) {
        this.dialogTitle = '新增设备'
        this.isUseFlag = false
        this.paramsObj.useFlag = 0
        this.isLook = false
      } else if (flag == 1) {
        this.$nextTick(() => {
          // 区域，机构的值回显
          let rs = this.$refs.editIpt
          rs.cgxqy = row.areaId
          rs.cxjgmc().then(() => {
            rs.cjgmc = row.organizationId
            this.paramsObj.organizationId = rs.cjgmc
          })
        })
        this.paramsObj = Object.assign({}, row)
        this.dialogTitle = '编辑设备'
        this.isUseFlag = true
        this.isLook = false
      } else if (flag == 2) {
        this.isLook = true
        this.dialogTitle = '查看设备接入授权码'
        this.ldeviceName = row.name
        this.ldeviceId = row.id
      }
    },
    // 确认
    confirmClk() {
      if (!this.paramsObj.areaId) {
        this.$message.error('区域名称不能为空')
        return
      }
      if (!this.paramsObj.organizationId) {
        this.$message.error('机构名称不能为空')
        return
      }
      if (!this.paramsObj.name) {
        this.$message.error('设备名称不能为空')
        return
      }
      if (!this.paramsObj.type) {
        this.$message.error('设备类型不能为空')
        return
      }
      if (!this.paramsObj.deviceCode) {
        this.$message.error('设备编号不能为空')
        return
      }
      // if(!this.paramsObj.deviceIp){
      //   this.$message.error('设备IP不能为空');
      //   return;
      // }
      // if(!this.paramsObj.devicePort){
      //   this.$message.error('设备端口不能为空');
      //   return;
      // }
      if (String(this.paramsObj.useFlag) == '' && this.dialogType == 1) {
        this.$message.error('状态不能为空')
        return
      }
      if (this.dialogType == 0) {
        deviceSave(qs.stringify(this.paramsObj)).then((data) => {
          if (data) this.returnState(data)
          this.paramsObj = this.paramsObj
        })
      } else if (this.dialogType == 1) {
        deviceUpdate(qs.stringify(this.paramsObj)).then((data) => {
          if (data) this.returnState(data)
        })
      }
    },
    // 解绑
    unbind({ id }) {
      this.$confirm('是否解绑该设备?', '提示', {
        confirmButtonText: '解绑',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          radMqttUnbindDevice({ deviceId: id }).then((res) => {
            console.log(res)
            if (res) {
              this.$message.success(res.message)
              this.searchs(this.currpage, this.pagesize)
            }
          })
        })
        .catch(() => {})
    },
    // 删除
    deletes(id) {
      this.$confirm('是否删除该条数据?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deviceDelete(qs.stringify({ id })).then((data) => {
            if (data) {
              let deleteAfterPage = Math.ceil((this.totals - 1) / this.pagesize)
              let currentPage = this.currpage > deleteAfterPage ? deleteAfterPage : this.currpage
              this.currpage = currentPage < 1 ? 1 : currentPage
              this.returnState(data)
            }
          })
        })
        .catch(() => {})
    },
    // 返回状态
    async returnState(data) {
      await this.$message.success(data.message)
      this.cancles()
      this.searchs(this.currpage, this.pagesize)
    },
    // 取消
    cancles() {
      this.dialogVisible = false
      this.isUseFlag = false
      this.paramsObj = {}
      this.$bus.$emit('clickResert', 2)
    },
    // 重置1
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.csbmc = ''
      this.csblx = ''
      this.csbcode = ''
      this.cisOnline = ''
      this.$store.commit('deviceId', [null, null])
      this.searchs(1, 10)
    },
    // 设备跳转
    deviceClk(row) {
      let path = ''
      this.$store.commit('DEVICEIDS', [row.areaId, row.organizationId, row.id])
      switch (row.type) {
        case 1:
          path = '/GdsHistory'
          break
        case 2:
          // 核素跳转到患者管理
          path = '/NuclidePatient'
          break
        case 3:
          path = '/DsaHistory'
          break
        case 4:
          path = '/DoseHistory'
          break
        case 5:
          path = '/BiaoMianHistory'
          break
        case 6:
          path = '/HandFootHistory'
          break
        case 7:
          path = '/CircuitHistory'
          break
        case 8:
          path = '/NeutronHistory'
          break
        case 17:
          path = '/ChannelHistory'
          break
        case 18:
          path = '/PedestrianChannelHistory'
          break
        case 19:
          path = '/DrinkWaterHistory'
          break
        case 20:
          path = '/RecordHistory'
          break
      }
      this.$router.push(path)
    },
  },
  mounted() {
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.searchs(1, this.pagesize)
  },
  destroyed() {
    this.$store.commit('deviceId', [null, null])
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
.ovText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cellHover:hover {
  border-radius: 8px;
  background: #409eff;
  cursor: pointer;
}
/deep/.cellSty {
  > div {
    padding: 0;
  }
}
/deep/.btnList {
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin: 2px;
    }
  }
}
</style>
